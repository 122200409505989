export const colors = {
  navbarBackground: '#494a50',
  menuLink: '#f0f510',
  logo: '#dfe842',
  homeBackground: '#FFF',
  aboutBackground: '#fdfd65',
  contactBackground: '#000',
  homeTitle: '#410fa3',
  aboutTitle: '#dfe842',
  contactTitle: '#a5a5a5',
  mailTitle: '#000000',
  comingSoonBG: '#6610f2',
  //storyText: '#006700',
}


// logo: '#11caa0',
//aboutBackground: '#410fa3',